import React from 'react';
import {Navigate, Route} from 'react-router-dom';
import {apiDocsPlugin, ApiExplorerPage} from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import {ScaffolderPage, scaffolderPlugin} from '@backstage/plugin-scaffolder';
import {orgPlugin} from '@backstage/plugin-org';
import {SearchPage} from '@backstage/plugin-search';
import {TechRadarPage} from '@backstage/plugin-tech-radar';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import {TechDocsAddons} from '@backstage/plugin-techdocs-react';
import {ReportIssue} from '@backstage/plugin-techdocs-module-addons-contrib';
import {UserSettingsPage} from '@backstage/plugin-user-settings';
import {apis} from './apis';
import {entityPage} from './components/catalog/EntityPage';
import {searchPage} from './components/search/SearchPage';
import {Root} from './components/Root';

import {createApp} from '@backstage/app-defaults';
import {AppRouter, FlatRoutes} from '@backstage/core-app-api';
import {CatalogGraphPage} from '@backstage/plugin-catalog-graph';
import {RequirePermission} from '@backstage/plugin-permission-react';
import {catalogEntityCreatePermission} from '@backstage/plugin-catalog-common/alpha';
import {CssBaseline, ThemeProvider} from '@material-ui/core';
import LightIcon from '@material-ui/icons/WbSunny';
import bluesoftTheme from './BluesoftTheme';

import {
  AlertDisplay,
  OAuthRequestDialog, SignInPage, SignInProviderConfig,
} from '@backstage/core-components';
import {microsoftAuthApiRef} from "@backstage/core-plugin-api";

// import {
//   AlertDisplay,
//   OAuthRequestDialog, ProxiedSignInPage,
// } from '@backstage/core-components';

const microsoftAuthProvider: SignInProviderConfig = {
  id: 'azure-auth-provider',
  title: 'Microsoft Active Directory',
  message:
    'Sign in to Backstage Application using your Active Directory account.',
  apiRef: microsoftAuthApiRef,
};

const app = createApp({
  apis,
  bindRoutes({bind}) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
  themes: [
    {
      id: 'my-theme',
      title: 'My Custom Theme',
      variant: 'dark',
      icon: <LightIcon/>,
      Provider: ({children}) => (
        <ThemeProvider theme={bluesoftTheme}>
          <CssBaseline>{children}</CssBaseline>
        </ThemeProvider>
      ),
    },
  ],
  components: {
    // SignInPage: props => (
    //   <ProxiedSignInPage {...props} provider="oauth2Proxy"/>
    // ),
    SignInPage: props => (
      <SignInPage {...props} auto provider={microsoftAuthProvider} />
    ),
  },
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<Navigate to="catalog" />} />
    <Route path="/catalog" element={<CatalogIndexPage />} />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />} />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ReportIssue />
      </TechDocsAddons>
    </Route>
    <Route
      path="/create"
      element={
        <ScaffolderPage
          groups={[
            {
              title: 'CI/CD Services',
              filter: entity =>
                entity?.metadata?.tags?.includes('cicd') ?? false,
            },
            {
              title: 'RAG Provisioning Services',
              filter: entity =>
                entity?.metadata?.tags?.includes('rag-provisioning') ?? false,
            },
            {
              title: 'LLM Provisioning Services',
              filter: entity =>
                  entity?.metadata?.tags?.includes('llm-provisioning') ?? false,
            },
            {
              title: 'Knowledge Base Services',
              filter: entity =>
                entity?.metadata?.tags?.includes('knowledge-base') ?? false,
            },
            {
              title: 'Evaluation Services',
              filter: entity =>
                entity?.metadata?.tags?.includes('evaluation') ?? false,
            },
            {
              title: 'Onboarding Services',
              filter: entity =>
                entity?.metadata?.tags?.includes('onboarding') ?? false,
            },
            {
              title: 'Storage Provisioning Services',
              filter: entity =>
                entity?.metadata?.tags?.includes('storage-provisioning') ?? false,
            },
          ]}
        />
      }
    />
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route
      path="/tech-radar"
      element={<TechRadarPage width={1500} height={800} />}
    />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
